<template>
  <div class="diseaseBank" :class="this.radio == 1 ? 'xi' : 'zhong'">
    <hd-com></hd-com>
    <div class="banner">
      <div class="banner-text">
        <h3>疾病库</h3>
        <p>JI BING KU</p>
      </div>
    </div>
    <div class="content">
      <div class="content-box clearfix">
        <div class="content-left">
          <div class="zhongxi">
            <el-radio-group v-model="radio" @change="changeHandler">
              <!--<el-radio label="0" border>全部</el-radio>-->
              <el-radio label="2" border>中医</el-radio>
              <el-radio label="1" border>西医</el-radio>
            </el-radio-group>
          </div>
          <!--<div class="age">
                        <p>年龄</p>
                        <div>
                            <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                            <el-checkbox-group v-model="checkedAges" @change="handleCheckedAgesChange">
                                <el-checkbox v-for="age in ages" :label="age" :key="age">{{age}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>-->
          <div class="sidenav">
            <h3 @click="searchDisease(0, 0)">全部</h3>
            <el-menu class="el-menu-vertical-demo" unique-opened>
              <!--一级-->
              <template>
                <el-submenu :index="index + ''" :key="index" v-for="(sideNav,index) in diseaseDepartmentList">
                  <template slot="title">
                    <span>{{ sideNav.diseaseTypeName }}</span>
                  </template>
                  <!--二级-->
                  <div class="subtitle" style="border: 1px solid #f2f2f2">
                    <template v-for="(subItem,index2) in sideNav.children">
                      <el-submenu  v-if="subItem.children != null && Object.keys(subItem.children).length != 0" :index="index+'-'+index2 + ''" :key="index2">
                        <template slot="title">
                          {{ subItem.diseaseTypeName }}
                        </template>
                        <!--三级-->
                        <el-menu-item v-for="(subItem2, i) in subItem.children" :key="i"
                          :index="subItem2.diseaseTypeId + '4'" @click="searchDisease(subItem2.diseaseTypeId)">
                          <template slot="title">
                            {{ subItem2.diseaseTypeName }}
                          </template>
                        </el-menu-item>
                      </el-submenu>
                      <el-menu-item class="sub-item" v-else @click="searchDisease(subItem.diseaseTypeId)" :index="index+'-'+index2 + ''" >
                        {{ subItem.diseaseTypeName }}
                      </el-menu-item>
                    </template>
                  </div>
                </el-submenu>
              </template>
            </el-menu>
          </div>
        </div>
        <div class="content-right">
          <div class="result-tit">
            <p>
              为您找到 <span>{{ total }}</span><em>个结果</em>
            </p>
          </div>
          <ul class="result-list">
            <li class="resule-item clearfix" v-for="(item, i) in diseaseList" :key="i">
              <div>
                <div class="resule-left zy" :style="
                item.category.substring(0, 2) == '中医' ? 'background-color:#b76c12;' : 'background-color:#1fb7a6;'">
                  {{ item.category.substring(0, 4) }}
                </div>
                <div class="resule-info left">
                  <div class="clearfix">
                    <div class="cnname left" @click="toDiseaseDetail(item.diseaseKey)">
                      <h3>{{ item.diseaseName }}</h3>
                      <p :title="item.diseaseAlias">{{ item.diseaseAlias }}</p>
                    </div>
                    <div class="jiantou left" v-if="item.relation != null && item.relation.length != 0"
                      @click="toDiseaseDetail(item.diseaseKey)">
                      <div class="jiantou-img">
                        <img height="30px" :src="
                          item.category.substring(0, 2) == '中医'
                            ? require('../../assets/img/duizhao2.png')
                            : require('../../assets/img/duizhao.png')
                        " alt="" />
                      </div>
                      <p style="display: none">中西医对应疾病</p>
                    </div>
                    <div class="enname left" v-if="item.relation != null && item.relation.length != 0" :style="
                      item.category.substring(0, 2) == '中医'
                        ? 'color:#1fb7a6;'
                        : 'color:#b76c12;'
                    ">
                      <span v-for="(relat, k) in item.relation" @click="toDiseaseDetail(relat.relationKey)" :key="k">{{
                          relat.relationName
                      }}
                      </span>
                    </div>
                  </div>
                  <div class="symptom">
                    <p v-if="item.mainSymptom">
                      主要症状：<span>{{ item.mainSymptom }}</span>
                    </p>
                  </div>
                  <div class="img-box clearfix" v-if="
                    (item.category.indexOf('眼科') != -1 ||
                      item.category.indexOf('皮肤性病科') != -1) &&
                    item.image != null
                  ">
                    <div class="img-item left" v-for="(imgItem, i) in item.images" :key="i">
                      <el-image :src="imgItem" :preview-src-list="item.images" fit="cover">
                      </el-image>
                    </div>
                  </div>
                </div>
                <div class="resule-star right">
                  <p>
                    <!-- <span class="on-col" @click="onCollection(item)">收藏</span> -->
                    <!-- <span v-else class="add-col" @click="onCollection">已收藏</span> -->
                    <span class="on-cpt" @click="onCompatibility($event)">忽略</span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div id="page">
            <el-pagination background layout="prev, pager, next,total" :page-size="15"
              @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
  </div>
</template>

<script>
import hdCom from "../../components/header"; //引入组件
import ftCom from "../../components/footer"; //引入组件
// import rtBar from "../../components/right";
import { Disease } from "../../components/Disease/Disease";
import { Collection } from "../../components/collection/Collection";
const ageOptions = ["婴儿", "儿童", "成年", "老人", "孕妇"];
var searchId = 0;
var pageIndex = 1;
var department = 0;
var typeId = 0;
var keyword = "";
var isClinical = 0;
var radio = '2'
export default {
  name: "DiseaseBank",
  components: {
    hdCom,
    ftCom,
    // rtBar,
  },
  data() {
    var disease = new Disease(this.TokenClient, this.Services.Disease);
    var collection = new Collection(this.TokenClient, this.Services.Statics);
    return {
      radio: "2",
      checkAll: false,
      checkedAges: [],
      ages: ageOptions,
      isCollection: false,
      isCompatibility: false,
      //疾病科室
      diseaseDepartmentList: [],
      //疾病列表
      diseaseList: [],
      total: 0,
      currentPage: 1, //初始页
      categoryName: "西医",
      rloading: [], //loading遮罩
      diseaseDomain: disease,
      collectionDomain: collection,
    };
  },
  mounted() {
    this.$bus.$emit('isShowChangeSize');
    this.$bus.$emit("select", "disease");
    keyword = this.$route.query.keyword;
    isClinical = this.$route.query.isClinical;
    this.searchDisease(department,pageIndex);
    
  },
  watch: {
    $route() {
      keyword = this.$route.query.keyword;
      isClinical = this.$route.query.isClinical;
      this.searchDisease(department,pageIndex);
    },
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedAges = val ? ageOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedAgesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.ages.length;
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
      
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    onCollection: function (item) {
      var _this = this;
      var entityType = 0;
      if (item.category.indexOf("西医") != -1) {
        entityType = 1;
      }
      if (item.category.indexOf("中医") != -1) {
        entityType = 2;
      }
      _this.collectionDomain.Put(
        "disease",
        item.diseaseName,
        item.diseaseKey,
        item.diseaseId,
        entityType,
        function (data) {
          _this.$message({
            type: "success",
            message: "收藏成功!",
          });
        },
        function (err) {
          if (err.status === 400) {
            _this.$message.error("请勿重复收藏");
          }
        }
      );
    },
    onCompatibility(event) {
      let target = event.target;
      $(target).parents(".resule-item").hide("slow");
      /*this.isCompatibility = !this.isCompatibility;*/
    },
    //radio change事件
    changeHandler: function (value) {
      this.radio = value;
      this.searchDisease(0, 0);
      this.getDiseaseDepartmentList()
    },
    //右侧药品列表获取
    searchDisease(diseaseType, isPage = false) {
      var _this = this
      department = diseaseType
      // console.log(department)
      this.rloading = this.openLoading();
      if (!isPage) pageIndex = 1;
      {
        var category = "全部";
        if (this.radio == 1) category = "西医科室";
        if (this.radio == 2) category = "中医科室";
      }
      // if (this.$route.query.headSelected == "zhengzhuang") {
      //   isClinical = 1
      // } else {
      //   isClinical = 0
      // }
      if (!isClinical) {
        isClinical = 0
      }
      this.diseaseDomain.mzClinicals(isClinical, category, pageIndex, department, keyword,
        function (res) {
          searchId = res.msg;
          _this.diseaseList = res.data.results;
          _this.total = res.data.dataTotal;
          _this.getDiseaseDepartmentList()
        },
        function (error) {
          console.log("疾病列表请求失败!请刷新重试:", error);
          _this.getDiseaseDepartmentList()
        }
      );
      this.rloading.close();
      if (!isPage) this.currentPage = 1;
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    getDiseaseDepartmentList() {
      var category = "全部";
      if (this.radio == 1) category = "西医科室";
      if (this.radio == 2) category = "中医科室";
      this.diseaseDomain.mzDiseaseDepartmentList(category,
        (res) => {
          this.diseaseDepartmentList = res.data;
          this.diseaseDepartmentList.forEach((item) => {
            if (item.children && item.children.length > 0) {
              item.children.unshift({
                diseaseTypeId: item.diseaseTypeId,
                diseaseTypeName: "全部"
              })
            } else {
              item.children = [{
                diseaseTypeId: item.diseaseTypeId,
                diseaseTypeName: "全部"
              }]
            }
          })
        },
        (error) => {
          console.log("疾病列表请求失败!请刷新重试:", error);
        }
      );
    },
    //分页点击事件
    handleCurrentChange: function (pageNum) {
      this.currentPage = pageNum;
      pageIndex = pageNum;
      this.searchDisease(department, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //跳转到详情
    toDiseaseDetail(hashKey) {
      this.$router.push({
        path: "/disease/DiseaseDetail",
        query: {
          hashKey: hashKey,
          searchType: "disease"
        },
      });
    },
  },

};
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

.xi .banner {
  height: 116px;
  background: url("../../assets/img/jbk_banner.png") center no-repeat;
}

.zhong .banner {
  height: 116px;
  background: url("../../assets/img/jbk_banner_zy.jpg") center no-repeat;
}

.banner-text {
  width: 1200px;
  margin: 0 auto;
}

.banner-text h3 {
  padding-top: 25px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 18px;
  color: #ffffff;
}

/*content*/
.content {
  background: #fcfcfc;
}

.content-box {
  width: 1300px;
  margin: 0 auto;
}

.content-left {
  float: left;
  width: 320px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}

.hot-search ul li {
  float: left;
  width: 33%;
  margin-top: 10px;
}

.hot-search ul li span {
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}

.hot-search ul li span:hover {
  color: #1fb7a6;
}

.zhongxi {
  margin-bottom: 15px;
}

.zhongxi li {
  float: left;
  margin-right: 10px;
  padding: 3px 0;
  width: 100px;
  border: 1px solid #cccccc;
  text-align: center;
}

.age {
  margin-top: 30px;
}

.age ul li {
  width: 33.3%;
  float: left;
  margin: 10px 0;
}

.sidenav {
  margin-top: 30px;
}

.sidenav>h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #1fb7a6;
  font-weight: bold;
}

/*content-right*/
.content-right {
  float: right;
  width: 900px;
}

.result-tit {
  padding: 35px 0 25px;
  font-size: 28px;
  font-weight: bold;
  color: #222222;
}

.result-tit span,
.result-tit em {
  color: #00ab98;
  font-style: normal;
}

.result-list {}

.resule-item {
  padding: 15px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 0 3px rgba(0, 0, 0, 0.08);
}

.resule-item:hover {
  box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.resule-info>div:nth-child(1) {}

.resule-left {
  float: left;
  padding: 6px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
}

.resule-left.zy {
  background: #b76c12;
}

.resule-left.xy {
  background: #1fb7a6;
}

.cnname {
  max-width: 310px;
  margin-left: 10px;
  margin-right: 35px;
  cursor: pointer;
}

.cnname h3 {
  font-size: 18px;
  color: #333333;
  max-width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cnname p {
  font-size: 14px;
  color: #808080;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jiantou {
  margin: 0 10px;
}

.jiantou p {
  font-size: 12px;
  color: #333;
}

.jiantou .jiantou-img {
  text-align: center;
  line-height: 30px;
}

.enname {
  width: 300px;
  font-size: 18px;
  color: #06a290;
  margin-left: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.symptom {
  width: 750px;
  margin: 15px 0 0 10px;
  color: #808080;
  font-size: 14px;
}

.symptom p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.resule-item:hover .resule-star {
  opacity: 1;
  transition: all linear 0.5s;
}

.resule-star {
  transition: all linear 0.5s;
  opacity: 0;
  /* margin-right: 30px; */
}

.resule-star p span {
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;
  text-decoration: underline;
}

.resule-star p span.add-cpt {
  color: #b72e0e;
}

.resule-star p span.add-col {
  color: #1fb7a6;
}

.resule-star i {
  font-size: 26px;
  color: #15aa9d;
}

/*page*/
#page {
  text-align: center;
  padding: 100px 0 30px;
}

.img-box {
  width: 800px;
  /* margin: 15px 0; */
  position: relative;
  /* height: 150px; */
  /* overflow: hidden; */
}

.img-item {
  width: 80px;
  height: 80px;
  margin-right: 5px;
}

.img-item .el-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 0 3px rgb(0 0 0 / 8%);
}

.xi .result-tit span,
.xi .result-tit em {
  color: #00ab98;
  font-style: normal;
}

.zhong .result-tit span,
.zhong .result-tit em {
  color: #b76c12;
  font-style: normal;
}

.xi .sidenav>h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #1fb7a6;
  font-weight: bold;
}

.zhong .sidenav>h3 {
  width: 310px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #ffffff;
  background: #b76c12;
  font-weight: bold;
}
</style>
<!-- 左侧导航样式 -->
<style  scoped>
.sidenav /deep/ .el-submenu__title>span {
  font-size: 16px;
  font-weight: 700;
}

.sidenav /deep/ .el-submenu__title i {
  color: #ffffff;
}

.xi /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #1fb7a6;
  color: #ffffff;
  margin-top: 7px;
}

.zhong /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #b76c12;
  color: #ffffff;
  margin-top: 7px;
}


.xi .subtitle /deep/ .el-submenu__title {
  padding-left: 30px !important;
  background: #fff;
  color: #333;
  margin-top: 0;
  height: 36px;
  line-height: 36px;
}

.xi .subtitle /deep/ .el-submenu__title:hover {
  background: rgba(31, 183, 166, 0.2);
}

.xi .subtitle /deep/ .el-submenu__title i {
  color: #000000;
}

.xi .el-menu-item.is-active,
.xi .el-menu-item:hover {
  color: #01c2ac;
  background: rgba(31, 183, 166, 0.2);
}

.zhong .el-menu-item.is-active,
.zhong .el-menu-item:hover {
  color: #b76c12;
  background: rgba(183, 108, 18, 0.2);
}

.el-image__inner {
  height: 150px;
  width: auto;
}

.diseaseBank .el-radio.is-bordered {
  border-radius: 2px;
  width: 156px;
  text-align: center;
}

.diseaseBank .el-radio.is-bordered+.el-radio.is-bordered {
  margin-left: 5px;
}

.diseaseBank .el-radio {
  margin-right: 0;
}

.diseaseBank .el-checkbox-group {
  display: inline;
}

.diseaseBank .el-checkbox {
  width: 33.3%;
  margin: 10px 0;
}

.diseaseBank .el-checkbox__inner {
  width: 16px;
  height: 16px;
}

.diseaseBank .el-checkbox__inner::after {
  height: 9px;
  left: 5px;
  width: 4px;
}

.diseaseBank .el-badge__content.is-dot {
  height: 6px;
  width: 6px;
}

.diseaseBank .el-badge__content.is-fixed.is-dot {
  right: 0px;
  top: 11px;
}

.diseaseBank .el-submenu__title * {
  margin-left: 10px;
}

.el-menu {
  border-right: none;
}

.el-submenu__title i {
  color: #ffffff;
}

.el-menu-item,
.el-submenu__title {
  height: 48px;
  line-height: 48px;
}

.subtitle {
  border: 1px solid #f2f2f2;
}

.subtitle .el-submenu__title i {
  color: #333333;
}

.subtitle .el-submenu__title {
  padding-left: 30px !important;
  background: #ffffff;
  color: #333333;
  margin-top: 0;
  height: 36px;
  line-height: 36px;
}

.subtitle .el-submenu__title:hover {
  color: #1fb7a6;
}

.el-submenu .el-menu .sub-item {
  padding-left: 30px !important;
  height: 36px;
  line-height: 36px;
}

.el-submenu .el-menu-item {
  padding-left: 50px !important;
  height: 36px;
  line-height: 36px;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #ffffff;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  border-radius: 50%;
}

.el-pagination {
  font-weight: 500;
}

/**.头部按钮样式 */
.xi /deep/ .el-radio.is-bordered.is-checked {
  border-color: #01c2ac;
}

.xi /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #01c2ac;
  background: #01c2ac;
}

.xi /deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #01c2ac;
}

.zhong /deep/ .el-radio.is-bordered.is-checked {
  border-color: #b76c12;
}

.zhong /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #b76c12;
  background: #b76c12;
}

.zhong /deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #b76c12;
}

.zhong /deep/ .el-radio__inner:hover {
  border-color: #b76c12;
}

.el-image-viewer__close {
  font-size: 24px;
}
</style>
